




















import { Vue, Component, Watch } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import Header from './components/Header.vue';
import common from '@/utils/common';

@Component({
    name: 'CaseDetailPage',
    components: {
        Header
    }
})
export default class extends Vue {
  $i18n: any

  private content:any = {}

   @Watch('$i18n.locale')
  watchLocale(newVal:any) {
      if (newVal) {
          this.getCaseContent();
      }
  }

   mounted() {
       window.scrollTo({
           top: 0
       });
       this.getCaseContent();
       common.seoOptimize({
           title: this.$route.params.id === '1' ? 'EGG Repair workshop management system - Toptile Automax' : 'EGG Repair workshop management system - Ping Service',
           keywords: this.$route.params.id === '1' ? 'Garage management, workshop management system, mechanics management, Car repair system, workshop dashboard' : 'Garage management, workshop management system, mechanics management, Car repair system, workshop dashboard',
           description: this.$route.params.id === '1' ? 'EGG repair helps \'Toptile Automax\' to improve customer management, history record check to speed up problem investigation. Register and start your free trial!' : 'EGG repair helps "PING SERVICE" to create custom jobs and store customer data easily that help him to save much more time. Register and start your free trial!'
       });
   }

   private getCaseContent() {
       console.log(this.$route.params.id);
       if (this.$route.params.id === '1') {
           this.content = {
               caseTitle: this.$i18n.t('EGGRepair.case_title'),
               casePara1: this.$i18n.t('EGGRepair.case_paragraph1'),
               img1: require('../../assets/EGGRepair/case_detail_pic3.png'),
               caseTitle1: this.$i18n.t('EGGRepair.case_paratitle1'),
               casePara2: this.$i18n.t('EGGRepair.case_paragraph2'),
               img2: require('../../assets/EGGRepair/case_detail_pic1.png'),
               caseTitle2: this.$i18n.t('EGGRepair.case_paratitle2'),
               casePara3: this.$i18n.t('EGGRepair.case_paragraph3'),
               img3: require('../../assets/EGGRepair/case_detail_pic2.png'),
               casePara4: this.$i18n.t('EGGRepair.case_paragraph4'),
               img4: require('../../assets/EGGRepair/case_detail_pic4.png'),
               casePara5: this.$i18n.t('EGGRepair.case_paragraph5')
           };
           if (this.$i18n.locale === 'th') {
               this.content.img1 = require('../../assets/EGGRepair/case_detail_pic3_th.png');
           }
       } else {
           this.content = {
               caseTitle: this.$i18n.t('EGGRepair.case2_title'),
               casePara1: this.$i18n.t('EGGRepair.case2_paragraph1'),
               img1: require('../../assets/EGGRepair/case2_detail_pic1.png'),
               caseTitle1: this.$i18n.t('EGGRepair.case_paratitle1'),
               casePara2: this.$i18n.t('EGGRepair.case_paragraph2'),
               img2: require('../../assets/EGGRepair/case2_detail_pic2.png'),
               caseTitle2: this.$i18n.t('EGGRepair.case_paratitle2'),
               casePara3: this.$i18n.t('EGGRepair.case_paragraph3'),
               img3: require('../../assets/EGGRepair/case2_detail_pic3.png'),
               casePara4: this.$i18n.t('EGGRepair.case_paragraph4'),
               img4: require('../../assets/EGGRepair/case2_detail_pic4.png'),
               casePara5: this.$i18n.t('EGGRepair.case_paragraph5')
           };
           if (this.$i18n.locale === 'th') {
               this.content.img1 = require('../../assets/EGGRepair/case2_detail_pic1_th.png');
           }
       }
   }

   private handleChangeLang(command:string) {
       this.$i18n.locale = command;
       AppModule.SET_LANGUAGE(command);
       this.getCaseContent();
   }
}
